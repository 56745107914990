<template>
  <div class="wisdom">
    <transition name='right'>
      <div class="content_right" v-if="show">
        <div class="backbg">
          <div class="title">环境设备</div>
          <div class="content">
            <div class="numbox flex-between-center">
              <div class="box"></div>
              <div class="box2"></div>
              <div class="img flex-center">
                <img src="@/assets/img/wids.png" alt="">
              </div>
              <div class="numtext">设备数量</div>
              <div class="fontlinear">{{ detail.environmentDeviceNum || 0 }}</div>
            </div>
            <div class="flex-between-center table">
              <div class="table1"></div>
              <div class="table2">设备名称</div>
              <div class="table3">型号</div>
              <div class="table4">状态</div>
            </div>
            <div class="list">
              <div class="flex-between-center" v-for="(item, index) of environmentList" :key="index"
                :class="item.deviceStatus == 0 ? 'active' : ''">
                <div class="table1"></div>
                <div class="table2">{{ item.deviceName }}</div>
                <div class="table3">{{ item.deviceMode }}</div>
                <div class="table4">{{ item.deviceStatus ? "正常" : "离线" }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="backbg two">
          <div class="title">安全设备</div>
          <div class="content">
            <div class="numbox flex-between-center">
              <div class="box"></div>
              <div class="box2"></div>
              <div class="img flex-center">
                <img src="@/assets/img/wids.png" alt="">
              </div>
              <div class="numtext">设备数量</div>
              <div class="fontlinear">{{ detail.safetyDeviceNum || 0 }}</div>
            </div>
            <div class="flex-between-center table">
              <div class="table1"></div>
              <div class="table2">设备名称</div>
              <div class="table3">型号</div>
              <div class="table4">状态</div>
              <!-- <div class="table5" style="margin-right:35px">监控</div> -->
            </div>
            <div class="list">
              <div class="flex-between-center" v-for="(item, index) of safetyList" :key="index"
                :class="item.deviceStatus == 0 ? 'active' : ''">
                <div class="table1"></div>
                <div class="table2">{{ item.deviceName }}</div>
                <div class="table3">{{ item.deviceMode }}</div>
                <div class="table4">{{ item.deviceStatus ? "正常" : "离线" }}</div>
                <!-- <div class="table5">
                  <div class="select" @click="selectvik(item.deviceId)" :class="item.deviceId===act_id?'':'jkcardno'">
                    <img v-if="item.deviceId===act_id"
                      src="../../../public/static/img/jk/select.png"
                      alt=""/>
                </div> 
                </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div class="content_center_top">
      <card :num="detail.oldPeopleNum || 0" name="老人数" angin="center" />
      <card :num="detail.workTeamNum || 0" name="工作小组数" angin="center" />
      <card :num="detail.healthPercent || 0" name="健康率" angin="center" />
    </div>

    <div class="ImageText">
      <apopup ref="ImageText" title="设备详情">
        <div class="name">{{ device.deviceName }}</div>
        <div style="margin-bottom: 10px;">设备编码：{{ device.deviceNo }}</div>
        <div>运行状态：{{ device.deviceStatus }}</div>
      </apopup>
    </div>
    <transition name="right">
      <div class="diong" v-if=" jkshow">
        <div class="top">
          <div class="title">{{ '监控' }}</div>
          <div class="pdclose" @click="closehkvido"></div>
        </div>
        <div class="jkshow">
            <hkvido ref="hkvido" v-if="jkshow" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import card from '@/components/card.vue'
import apopup from '@/components/apopup.vue'
import hkvido from '@/components/hkone.vue'
import { wisdombase, wisdomdevicelist, wisdomdevicedetail,hikdetail } from '@/api/permission'
export default {
  name: 'wisdom',
  components: {
    card, apopup,hkvido
  },
  watch: {
    EntityData(newval) {
      if(newval.businessEntityId!=null){
        this.getdetail(newval.businessEntityId)
      }else{
        this.$message('点位未设置ID')
      }
    }
  },
  computed: {
    EntityData() {
      return this.$store.state.EntityData
    }
  },
  data() {
    return {
      show: false,
      villageId: "",
      detail: {},
      device: {},
      environmentList: [],
      safetyList: [],
      act_id:null,
      jkshow:false
    };
  },
  methods: {
    getwisdombase() {
      wisdombase({ villageId: this.villageId }).then(res => {
        this.detail = res || {};
      })
    },
    // 0 环境 1安全
    getwisdomdevicelist(type) {
      wisdomdevicelist({ villageId: this.villageId, deviceType: type }).then(res => {
        // if(!res) return;
        this.show = true;
        let list = res || [];
        if (type) {
          this.safetyList = list;
        } else {
          this.environmentList = list;
        }
      })
    },
    getwisdomdevicedetail(id, type) {
      this.$refs['ImageText'].close();
      let data = {}
      if (type) {
        data = { deviceId: id }
      } else {
        data = { mapMarkId: id }
      }
      data.villageId = this.villageId;
      wisdomdevicedetail(data).then(res => {
        this.device = res;
        this.$refs['ImageText'].show();
      })
    },
    selectvik(id){
      if(id==this.act_id){
        this.closehkvido()
        return
      }else{
        this.closehkvido()
        this.act_id=id;
        this.getdetail(id)
      }
    },
    getdetail(id){
      this.jkshow=true;
      hikdetail({id:id}).then(res=>{
        this.$refs.hkvido.realplay(document.location.protocol=='http:'?res.videoWsUrl:res.videoWssUrl)
      })
    },
    closehkvido(){
      this.act_id=null
      if(this.jkshow){
        this.$refs.hkvido.stopAllPlay()
        this.jkshow=false
      }else{
        this.detailshow = false;
      }
    }
  },
  mounted() {
    // this.$store.commit("SET_showtype", "1669171558218,1669171569498");
    this.$store.commit("SET_showtype", "1681874473854","1669171558218","1669171569498","1670926456927");
    this.villageId = this.$store.state.villageId;
    this.getwisdombase();
    this.getwisdomdevicelist(0);
    this.getwisdomdevicelist(1);
  },
}
</script>
<style src="@/assets/css/contentfixed.css" scoped>

</style>
<style lang="scss" src="@/assets/css/wisdom.scss" scoped>

</style>
<style lang="scss" scoped>
.ImageText {
  font-size: 14px;
  .name {
    font-size: 20px;
    margin-bottom: 15px;
  }
}
.select{
  height: 20px;
  width: 20px;
  border: 1px solid #fff;
  position: relative;
  cursor: pointer;
  img{
    width: 19px;
    height: 19px;
  }
}
.jkcardno{
  opacity: 0.5;
}
.diong {
  position: fixed;
  left: 16%;
  top: 23%;
  width: 900px;
  height: 680px;
  background: rgba(10, 42, 64, 0.9);
  border: 1px solid #064f89;
  .top {
    height: 40px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: rgba(62, 106, 161, 0.6);
    .title {
      margin-left: 10px;
      font-size: 18px;
      color: #ffffff;
      font-weight: 600;
      line-height: 38px;
      background: linear-gradient(180deg, #ffffff 0%, #6af6fa 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .pdclose {
      width: 18px;
      height: 18px;
      cursor: pointer;
      float: right;
      position: relative;
      overflow: hidden;
      margin-top: 10px;
      opacity: 0.5;
      margin-right: 10px;
    }
  }
}
</style>